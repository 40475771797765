const initSubscribeForm = () => {
  const form = document.querySelector('.sib-form')

  const errorBlock = form.querySelector('.entry__error')
  const messagePanels = Array.from(form.querySelectorAll('.sib-form-message-panel'))

  errorBlock.addEventListener('click', () => {
    errorBlock.style.display = 'none'

    const errorPanel = form.querySelector('.entry_errored')

    errorPanel.classList.remove('entry_errored')
  })

  messagePanels.map((panel) => {
    panel.addEventListener('click', () => {
      panel.style.display = 'none'
    })
  })

  window.REQUIRED_CODE_ERROR_MESSAGE = 'Please choose a country code'
  window.LOCALE = 'en'
  window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = 'Error message lorem ipsum.'

  window.REQUIRED_ERROR_MESSAGE = translation.error_message;

  window.GENERIC_INVALID_MESSAGE = 'Error message lorem ipsum.'

  window.translation = {
    common: {
      selectedList: '{quantity} list selected',
      selectedLists: '{quantity} lists selected'
    }
  }
}

export default initSubscribeForm
